



import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

@Component
export default class extends Mixins() {
  @Prop() title!: string
}
