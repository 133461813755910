



























































































































































import { Vue, Component, Mixins } from 'vue-property-decorator'

// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvBorder9 from '$ui/dv/packages/my-dv-border9'
// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'
// @ts-ignore
import MyDvHeader2 from '$ui/dv/packages/my-dv-header2'

import MyBox from '@/components/box.vue'
import MyTitle from '@/components/title1.vue'

import Amap from '@amap/amap-vue/lib/amap'

import MessageScroll from '@/components/message_scroll.vue'

import Timeline from '../echarts/timeline.vue'

import StadiumList from './components/stadium_list.vue'
import UserList from './components/user_list.vue'

@Component({
  components: {
    MyDvBox,
    MyDvBorder9,
    MyDvTitle,
    MyDvHeader2,
    MyBox,
    MyTitle,
    Amap,
    MessageScroll,
    Timeline,
    StadiumList,
    UserList,
  },
})
export default class extends Mixins() {
  mode: string = ''

  tblColumns: any[] = [
    {
      dataIndex: 'name',
      key: 'name',
      slots: { title: 'customTitle' },
      scopedSlots: { customRender: 'name' },
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Tags',
      key: 'tags',
      dataIndex: 'tags',
      scopedSlots: { customRender: 'tags' },
    },
    {
      title: 'Action',
      key: 'action',
      scopedSlots: { customRender: 'action' },
    },
  ]

  tblData: any[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
      tags: ['nice', 'developer'],
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
      tags: ['loser'],
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
      tags: ['cool', 'teacher'],
    },
  ]

  changeMode(mode: string) {
    this.mode = mode
  }

  mounted() {}

  beforeDestroy() {}
}
